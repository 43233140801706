








import Vue from 'vue';
import { VApp, VMain } from 'vuetify/lib';

export default Vue.extend({
  name: 'App',
  components: { VApp, VMain },
});
